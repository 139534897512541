<template>
  <div>
    <save
      ref="saveForm"
      @close="$refs.saveForm.visible = false"
      @onSuccess="getItems"
    />
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :has-show="true"
        model="auction"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => page = p"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          {{ props }}
        </template>
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'isHidden'">
            <b-badge
              v-if="props.row.is_hidden === 1"
              pill
              variant="light-warning"
            >Yashirilgan</b-badge>
            <b-badge
              v-if="props.row.is_hidden === 0"
              pill
              variant="light-info"
            >Ochiq</b-badge>
          </span>
          <!-- </template>
        <template slot="table-column" slot-scope="props"> -->
          <span v-if="props.column.field === 'statusAuction'">
            <b-badge
              v-if="props.row.status=='open'"
              pill
              variant="light-warning"
            >Jarayonda</b-badge>
            <b-badge
              v-if="props.row.status=='sold'"
              pill
              variant="light-success"
            >Soltilgan</b-badge>
            <b-badge
              v-if="props.row.status=='closed'"
              pill
              variant="light-danger"
            >Sotilmagan</b-badge>
          </span>
        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      roles: [],
      filterModel: {
        per_page: 50,
        firstname: null,
        lastname: null,
        address: null,
        region_id: null,
        city_id: null,
        relations: 'category',
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Auksiondagi mahsulot'),
          field: 'category.name_uz',
        },
        {
          label: 'Barkod',
          field: 'barcode',
        },
        {
          label: 'Boshlang`ich narxi (so`m)',
          field: 'start_price',
          isAmount: true,
        },
        {
          label: 'Qadam narxi (so`m)',
          field: 'step_amount',
          isAmount: true,
        },
        {
          label: 'Boshlanish vaqti',
          field: 'begin_at',
          isDateTime: true,
        },
        {
          label: 'Tugash vaqti',
          field: 'finish_at',
          isDateTime: true,
        },
        {
          label: 'Auksion turi',
          field: 'isHidden',
        },
        {
          label: 'Holat',
          field: 'statusAuction',
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'auction/GET_ITEMS',
    }),
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'auction/index',
      destroyAction: 'auction/destroy',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
      this.loading = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
    mapCols(filterName, res) {
      this.columns.map(item => {
        if (item.field === filterName) {
          item.filterOptions.filterDropdownItems = res.data.data.map(i => {
            i.value = i.id
            i.text = i.name
            return i
          })
        }
        // if (item.field === 'status') {
        //   item.filterOptions.filterDropdownItems = [
        //     { value: 1, text: this.$t('Активный') },
        //     { value: 0, text: this.$t('Неактивный') },
        //   ]
        // }
      })
    },
  },
}
</script>

<style scoped></style>
