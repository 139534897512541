<template>
  <b-modal v-model="visible" size="lg" cancel-variant="outline-secondary" :ok-title="$t('Сохранить')"
    no-close-on-backdrop :cancel-title="$t('Отмена')" centered :title="title" @ok.prevent="save">
    <validation-observer ref="form" #default="{ invalid }">
      <b-form class="auth-login-form mt-2">
        <b-row>
          <b-col cols="4">
            <b-form-group :label="$t('Код товара')" label-for="barcode">
              <validation-provider #default="{ errors }" :name="$t('Код товара')" rules="required">
                <b-overlay :show="barcodeInputLoading">
                  <b-form-input id="barcode" ref="barcode" v-model="form.barcode" name="barcode"
                    placeholder="000000000000" maxlength="12" :state="errors.length > 3 ? false : null" @input="
                      getProductByBarcode(form.barcode)
                      " />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-overlay>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="product" cols="8">
            <p>Mahsulot nomi: <b>{{ product.category.name_uz }}</b></p>
            <p>Narxi: <b>{{ calcCostPrice(product.category.price_dollar) | formatPrice }} сўм ({{
              product.category.price_dollar }} $)</b></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Boshlang'ich narxi (so'm)">
              <cleave v-model="form.start_price" class="form-control" :options="cleaveOption" placeholder="0"
                @input="changeSalePrice(form.start_price)" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Oshib borish narxi (so'm)">
              <cleave v-model="form.step_amount" class="form-control" :options="cleaveOption" placeholder="0"
                @input="changeSalePrice(form.start_price)" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Boshlanish sanasi">
              <el-date-picker v-model="form.begin_at" class="w-85" type="datetime" placement="bottom-start"
                :picker-options="pickerOption" value-format="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Tugash sanasi">
              <el-date-picker v-model="form.finish_at" class="w-85" type="datetime" placement="bottom-start"
                :picker-options="pickerOption" value-format="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Mahsulotni yashirish" label-for="investor_id">
              <b-form-checkbox v-model="form.is_hidden" :value="1" :unchecked-value="0">
                Mahsulotni yashirish
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="3">
            <b-form-group
              :label="$t('Ulushi(%)')"
              label-for="profit_percent"
            >
              <validation-provider
                #default="{ errors }"
                name="profit_percent"
                rules="required"
              >
                <b-form-input
                  id="profit_percent"
                  v-model="form.profit_percent"
                  v-mask="'##'"
                  type="number"
                  :state="errors.length > 3 ? false : null"
                  name="profit_percent"
                  placeholder="40%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      barcodeInputLoading: false,
      form: {
        barcode: null,
        category_id: null,
        is_hidden: 0,
        start_price: null,
        step_amount: null,
        begin_at: null,
        finish_at: null,
        id: null,
      },
      product: null,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      pickerOption: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() < new Date().setHours(0, 0, 0, 0)
        },
      },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/GET_ITEMS',
      regions: 'region/GET_ITEMS',
    }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.product = null
          clearObject(this.form)
        }, 200)
      }
      if (newVal) {
        const now = new Date()
        this.form.begin_at = `${now.getFullYear()}-${
          (`0${now.getMonth() + 1}`).slice(-2)}-${
          (`0${now.getDate()}`).slice(-2)} ${
          (`0${now.getHours()}`).slice(-2)}:${
          (`0${now.getMinutes()}`).slice(-2)}:${
          (`0${now.getSeconds()}`).slice(-2)}`
        const finish = new Date(now.getTime() + 3600 * 1000)
        this.form.finish_at = `${finish.getFullYear()}-${(`0${finish.getMonth() + 1}`).slice(-2)}-${(`0${finish.getDate()}`).slice(-2)} ${(`0${finish.getHours()}`).slice(-2)}:${(`0${finish.getMinutes()}`).slice(-2)}:${(`0${finish.getSeconds()}`).slice(-2)}`
      }
    },
  },
  mounted() {
    this.title = this.$t('Auksion yaratish')
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        if (!this.form.is_hidden) {
          this.form.is_hidden = 0
        }
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.getItem({
        id: item.id,
      }).then(res => {
        this.form.barcode = res.data.barcode
        this.form.category_id = res.data.category_id
        this.form.is_hidden = res.data.is_hidden
        this.form.start_price = res.data.start_price
        this.form.step_amount = res.data.step_amount
        this.form.begin_at = res.data.begin_at
        this.form.finish_at = res.data.finish_at
        this.form.id = res.data.id
        this.visible = true
      })
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    calcCostPrice(calcCostPrice) {
      return Number(calcCostPrice) * this.dollarRate
    },
    getProductByBarcode(barcode) {
      if (barcode && barcode.length === 12) {
        this.barcodeInputLoading = true

        const current_quantity = 0
        this.getWarehouseProducts({ barcode, current_quantity })
          .then(res => {
            if (res.success) {
              this.product = res.data
              this.form.category_id = res.data.category_id
              if (res.data.category.price_dollar) {
                this.form.start_price = res.data.category.price_dollar * 0.5 * this.dollarRate
                this.form.step_amount = this.form.start_price * 0.1
              }
            }
          })
          .finally(() => {
            this.barcodeInputLoading = false
          })
      } else if (barcode && barcode.length > 12) {
        this.form.barcode = Number(
          String(barcode).slice(0, 12),
        )
      }
    },
    changeSalePrice(product) {
      // const valid = this.validationForm()
      // console.log(valid)
      // if (Number(product.real_price) < Number(this.dollarRate) * Number(product.cost_dollar)) {
      //   this.isWrongSale = true
      // } else {
      //   this.isWrongSale = false
      // }
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      getRegionsAction: 'region/index',
      getWarehouseProducts: 'productCategory/warehouseProductsByBarcode',
      storeItem: 'auction/store',
      getItem: 'auction/show',
      updateItem: 'auction/update',
    }),
  },
}
</script>

<style scoped></style>
